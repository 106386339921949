* {
    font-family: ubuntu;
}

.mr-0 {
    margin-right: 0;
}

.ml-0 {
    margin-left: 0;
}

.pl-0 {
    padding-left: 0;
}

body {
    background: #EEEEEE !important;
}

.country-icon {
    height: 15px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

ol li {
    margin-bottom: 25px;
}

ol li::marker {
    font-weight: 600;
}

a {
    color: #003A70;
    text-decoration: none;
}

a:hover {
    color: #B2C3D4;
}

$yellow: #003A70;
$brown: #404040;
$blue: #B2C3D4;
$angle: 8px;
$angle-o: 4px;

a.btn {
    margin-right: 15px;
    margin-bottom: 15px;
    top: 0;
    left: 0;
    transition: all .15s linear 0s;
    position: relative;
    display: inline-block;
    padding: 15px 15px;
    background-color: $yellow;

    text-transform: uppercase;
    color: white;
    font-family: arial;
    letter-spacing: 1px;

    box-shadow: -6px 6px 0 $blue;
    text-decoration: none;

    &:hover {
        top: 3px;
        left: -3px;
        box-shadow: -3px 3px 0 $blue;
        color: white;
        &::after {
            top: 1px;
            left: -2px;
            width: $angle-o;
            height: $angle-o;
        }

        &::before {
            bottom: -2px;
            right: 1px;
            width: $angle-o;
            height: $angle-o;
        }
    }

    &::after {
        transition: all .15s linear 0s;
        content: '';
        position: absolute;
        top: 2px;
        left: -4px;
        width: $angle;
        height: $angle;
        background-color: $blue;
        transform: rotate(45deg);
        z-index: -1;

    }

    &::before {
        transition: all .15s linear 0s;
        content: '';
        position: absolute;
        bottom: -4px;
        right: 2px;
        width: $angle;
        height: $angle;
        background-color: $brown;
        transform: rotate(45deg);
        z-index: -1;
    }
}

// a.btn { position: relative; }

// a:active.btn {
//   top: 6px;
//   left: -6px;
//   box-shadow: none;

//   &:before {
//     bottom: 1px;
//     right: 1px;
//   }

//   &:after {
//     top: 1px;
//     left: 1px;
//   }
// }

// a.btn {
//     background: #003A70;
//     background-image: linear-gradient(to right, #003A70 , #B2C3D4);
//     margin: 20px;
//     color: white;
//     border: 0;
//     border-radius: 0;
//     padding: 15px;
//     transition: 0.5s;
//     border-radius: 5px;
// }

// a.btn:hover {
//     background: #003A70;
//     transition: 0.3s;
//     color: white;
// }

.table-header {
    padding: 15px;
    color: white;
    background: #003A70;
    width: 100%;

    h2 {
        font-size: 20px;
        margin-bottom: 0;
    }
}