.page-content {
    padding: 25px;
}

.calendar-frame {
    min-height: 400px;
    width: 100%;
}

.sidebar {
    border-left: 1px solid black;
}