.footer {
    color: white;
    text-align: center;
    a {
        text-decoration: none;
        color: white
    }
    .footer-card {
        border-radius: 10px;
        padding: 50px;
        background: #003A70;
        background-image: linear-gradient(#003A70, #B2C3D4);
        margin: 10px 0;
        .footer-icon {
            height: 100px
        }
    }
    .footer-card:hover {
        background: #003A70;
    }
}

.copyright {
    background: #003A70;
    color: white;
    text-align: center;
    padding: 25px;
    p {
        padding: 0;
        margin: 0
    }
    a {
        color: white;
        font-weight: 400;
    }
}