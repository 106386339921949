.navbar {
    min-height: 100px;
    background-color: #003A70;
    padding: 0 !important;
}

.navbar .branding {
    max-height: 90px;
    padding: 0.25em 0;
}

@media(max-width: 768px){
    .navbar-brand {
        margin: 0 auto;
    }
    .navbar .container {
        padding-right: 0 !important;
    }
    .navbar-collapse {
        width: 100%;
        position: absolute;
        background: #003A70;
        left: 0;
        top: 100px;
        padding-top: 10px;
        z-index: 100;
    }
    .nav-link {
        text-align: center;
    }
}


.navbar-toggler {
    color: white !important;
    font-weight: 700;
    font-size: 2rem;
    width: auto !important;
    border: 0;
}

.navbar-nav,
.navbar-nav:active,
.navbar-light .navbar-nav .nav-link {
    color: white !important;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
}

.dropdown-menu {
    border-radius: 0;
}

.dropdown-menu a {
    color: #003A70 !important;
}

.dropdown-menu a:hover {
    color: white !important;
    background-color: #003A70
}

.navbar-golfball {
    max-height: 100px;
}

// .navbar-toggler {
//     width: 100%;

//     &:focus {
//         box-shadow: none;
//     }
// }

// .navbar .nav-item:hover .dropdown-menu{ display: block; }

// .navbar-nav > li {
//     display: inline-block;
//     float:none;
// }

// .golf-img {
//     max-height: 150px;
//     height: 150px;
//     position: absolute;
//     right: -35px;
//     top: -15px;
// }