.title {
    background: #003A70;
    padding: 25px;
    text-align: center;
    color: white;
    margin-bottom: 25px;
    h2 {
        font-size: 30px;
        margin-bottom: 0;
    }
}

@media(max-width: 768px) {
    .title {
        margin-top: 25px;
    }
}