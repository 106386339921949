.table-parent {
    padding: 15px;
    background: #003a70e6;

    thead tr {
        background: white;
        color: #003a70;
        text-transform: capitalize;
        border-radius: 15px;
    }
    tbody {
        color: white;
    }
    .view-more {
        background: red;
        color: white;
        width: 100%;   
        border: 0;
        margin: 15px auto;
        border-radius: 5px;
    }
    .view-more:disabled,
    .table-btn:disabled {
        background: grey;
    }
    button.table-btn {
        color: white !important;
        background: red;
        border-radius: 5px;
        padding: 5px;
        border: none;
    }
}
