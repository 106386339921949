.welcome-hero {
      /* Full height */
  min-height: 500px; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 0 10% 10% 0; margin-top: 25px;
}
@media(max-width: 768px){
    .welcome-hero {
        min-height: 150px;
    }
}

.welcome-message {
    text-align: center;
    padding: 25px;
    h1 {
        font-size: 60px;
        text-transform: capitalize;
    }
    .quote {
        font-size: 22px;
    }
}

#twitter-widget-0 {
    border-radius: 10px;
}

.twitch-stream {
    background: #003A70;
    background-image: linear-gradient(to right, #003A70 , #B2C3D4);
    border-radius: 10px 0 0 10px;

    iframe {
        border-radius: 10px;
    }
    .stream {
        width: 100% !important;
    }
}

.discord-invite {
    background: #003A70;
    background-image: linear-gradient(to right, #B2C3D4, #003A70);
    border-radius: 0 10px 10px 0;

    iframe {
        border-radius: 10px;
        width: 100%;
        min-height: 500px;
    }
    .invite {
        width: 100% !important;
    }
}