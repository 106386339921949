.headlines-carousel {
    .card {
        margin: 0 10px 10px 0;
        background-color: white;
        .text {
            padding: 25px;
        }
    
        h3 {
            font-size: 20px;
        }
    
        img {
            height: 200px;
        }
    
        .excerpt {
            font: 13px;
            font-weight: 400;
        }
    }
}
