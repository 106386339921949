.testimonial-item {
    background-color: #003A70;
    padding: 25px;
    color: white;
    min-width: 335px;
    border-radius: 10px;
    margin: 0 10px 10px 0 !important;
    cursor: pointer;
    
    p {
        text-align: end;
        min-height: 150px;
    }
    .person {
        color: white;
        vertical-align: middle;
        text-align: left;
        small {
            display: block;
        }
        .info {
            margin-top: -5px;
        }
        .avatar {
            max-height: 40px;
            min-width: 75px;
            border-radius: 25%;
            margin-right: 25px;
        }
    }

    @media(max-width: 768px){
        .person {
            margin-top: 0;

            .info {
                margin-left: 10px;
            }
        }
    }
}

@media(max-width: 768px){
    .testimonial-item {
        margin: 0 import !important;
    }
}